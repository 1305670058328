import * as React from "react";
import PageComponent from "src/components/pages/learn/what-is-life-insurance";

const OurTeamPage = ({ location }) => {
  const copy = {
    text: `Life Insurance is a contract that promises to pay a financial benefit
      at the death of the insured as long as the policy is in effect. A premium is
      paid to the issuer of the contract, typically a life insurance company, and
      as long as the policy is in effect, the insurer will pay a death benefit to
      the named beneficiary at the death of the insured.`,
  };
  return (
    <PageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="What is Life Insurance?"
    />
  );
};

export default OurTeamPage;
